// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from '@src/views/pages/user/store/reducer'
import password from '@src/views/pages/authentication/store/reducer'

// Changed True
import profile from '@src/store/reducer/ProfileReducer'
import map from '@src/store/reducer/MapReducer'
import law from '@src/store/reducer/LawReducer'
import policy from '@src/store/reducer/PolicyReducer'
import category from '@src/store/reducer/CategoryReducer'
const rootReducer = combineReducers({
  auth,
  users,
  navbar,
  layout,
  password,
  profile,
  map,
  law,
  policy,
  category
})

export default rootReducer


