// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { ToastContainer } from "react-toastify";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import { IntlProviderWrapper } from "./utility/context/Internationalization";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import TagManager from "react-gtm-module";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
// const tagManagerArgs = {
//     gtmId: 'GTM-WR3W2T8'
// }
// TagManager.initialize(tagManagerArgs)

// replace console.* for disable log on production
// if (process.env.NODE_ENV === 'production') {
// console.log = () => {}
// console.error = () => {}
// console.debug = () => {}
// }
const redirectUri = `${window.location.origin}/map`;

const tagManagerArgs = {
  gtmId: "G-W7QXTREC6N",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Auth0Provider
    domain="login.mycoa.io"
    clientId="9AkBIdtmutJYmV8Fr13blorhHylR1qMo"
    redirectUri={redirectUri}
    audience="https://dev-qgf3-cce.us.auth0.com/api/v2/"
    scope="openid profile email read:current_user read:current_user_metadata"
  >
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <IntlProviderWrapper>
              <LazyApp />
              <ToastContainer newestOnTop />
            </IntlProviderWrapper>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
