// ** Initial State
const initialState = {
  list: [],
  data: null,
  import: [],
  merge: [],
  lawPaginatedList: {
    limit: 30,
    page: 0,
    total_pages: null,
    total_records: null,
    data: null,
  },
};

const law = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LAWS":
      return { ...state, list: action.data };
    case "GET_LAW":
      return { ...state, data: action.data };
    case "EMPTY_LAW":
      return { ...state, data: null };
    case "IMPORT_LAWS":
      return { ...state, import: action.data };
    case "MERGE_LAWS":
      return { ...state, import: action.data };
    case "GET_ALL_LAW_DATA_WITH_PAGINATION":
      return { ...state, lawPaginatedList: action.data };
    default:
      return { ...state };
  }
};
export default law;
