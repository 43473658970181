// ** Initial State
const initialState = {
    list : [],
    data : null,
    import : [],
    merge:[],
    policyForList: {
    "limit": 30,
    "page": 0,
    "total_pages": null,
    "total_records": null,
    "data": null
    }
  }

  const policyData = (state = initialState, action) => {
    switch (action.type) {   
      case 'GET_POLICY_DATA':
        return { ...state, list: action.data } 
     case 'GET_POLICY_VAL':
        return { ...state, data: action.data } 
      case 'SET_POLICY_DATA':
        return { ...state, data: [] } 
      case 'IMPORT_POLICIES':
        return { ...state, import: action.data }
      case 'MERGE_POLICIES':
        return { ...state, merge: action.data }
      case 'GET_ALL_CONTACT_DATA_WITH_PAGINATION':
        return {...state, policyForList: action.data}
      default:
        return { ...state }
    }
  }
  export default policyData
  