// ** Initial State
const initialState = {
    list : [],
    data : null,
    deletedCategoryData:null,
    categoryForList: {
    "limit": 30,
    "page": 0,
    "total_pages": null,
    "total_records": null,
    "data": null
    }
  }
const category = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_CATEGORIES':
        return { ...state, list: action.data }
    case 'GET_CATEGORY':
        return { ...state, data: action.data }
    case 'DELETE_CATEGORY':
        return { ...state, deletedCategoryData: action.data}
    case 'GET_ALL_CATEGORY_DATA_WITH_PAGINATION':
        return {...state, categoryForList: action.data}
    default:
      return { ...state }
    }
  }
  export default category
  