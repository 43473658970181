// ** Initial State
const initialState = {
    data: null,
    update: null
  }
  
  const profile = (state = initialState, action) => {
    switch (action.type) {   
      case 'SET_USER_PROFILE_EMPTY':
        return { ...state, data: action.data } 
      case 'GET_USER_PROFILE':
        return { ...state, data: action.data } 
      case 'UPDATE_USER_PROFILE':
        return { ...state, update: action.data } 
      default:
        return { ...state }
    }
  }
  export default profile
  