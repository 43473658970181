// ** Initial State
const initialState = {
  categories: [],
  laws: [],
  policies: [],
  resources: [],
  policy: [],
  law:[],
  policies_laws:{policies:null,laws:null,category:null}
}

const map = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CATEGORIES':
      return { ...state, categories: action.data }
    case 'GET_POLICY_LAW':
      return { ...state, laws: action.data.laws, policies: action.data.policies, resources: action.data.resources }
    case 'SET_POLICY_LAW':
      return { ...state, laws: [], policies: [], resources: [], policies_laws: [], category:null}
    case 'SET_POLICY_LAW_BY_CATEGORY':
      return { ...state, policies_laws: {policies:null,laws:null,category:null} }
    case 'GET_POLICY_LAW_BY_CATEGORY':
      return { ...state, policies_laws: action.data }
    // case 'GET_COLORS':
    //   return { ...state, colors: action.data }
    case 'GET_POLICY':
      return { ...state, policy: action.data }
    case 'GET_LAWS':
      return { ...state, laws: action.data }
    case 'GET_LAW':
      return { ...state, law: action.data }
    default:
      return { ...state }
  }
}
export default map
