// ** Initial State
const initialState = {
  data: null,
  pass: null,
}

const password = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER':
      return { ...state, data: action.data }
    case 'CREATE_PASSWORD':
      return { ...state, pass: action.data }

    default:
      return { ...state }
  }
}
export default password
